import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../../assets/mascot-attributes-small.svg'
import _imports_1 from '../../../assets/mascot-yivi-app-small.svg'


const _hoisted_1 = { class: "mt-4 mb-2" }
const _hoisted_2 = { class: "list-disc list-outside pl-4 my-4" }
const _hoisted_3 = { class: "whitespace-pre-line" }
const _hoisted_4 = { class: "w-3/4 lg:w-full flex flex-col" }
const _hoisted_5 = {
  method: "POST",
  action: "/yivi-endpoint/finish-and-redirect"
}
const _hoisted_6 = ["value"]

import { onMounted, ref } from 'vue';
	import { yivi } from '@/yivi';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'OnboardingStep5',
  setup(__props) {

	const yivi_token = ref<string>('');

	onMounted(() => {
		yivi(true, yivi_token);
	});

	window.addEventListener('pageshow', () => yivi(true, yivi_token));

return (_ctx: any,_cache: any) => {
  const _component_Progressbar = _resolveComponent("Progressbar")!
  const _component_OnboardingTitle = _resolveComponent("OnboardingTitle")!
  const _component_OnboardingTemplate = _resolveComponent("OnboardingTemplate")!

  return (_openBlock(), _createBlock(_component_OnboardingTemplate, null, {
    column1: _withCtx(() => [
      _createVNode(_component_Progressbar, { currentStep: 3 }),
      _createVNode(_component_OnboardingTitle),
      _createElementVNode("p", null, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('register.step', [3])), 1),
        _createTextVNode("   " + _toDisplayString(_ctx.$t('register.overview_step3')) + ". ", 1)
      ]),
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('register.rewards.almost_done')), 1),
      _createElementVNode("ol", _hoisted_2, [
        _createElementVNode("li", null, _toDisplayString(_ctx.$t('register.overview_step3')), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t('register.data_share')), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t('register.add_registration')), 1)
      ]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('register.rewards.hooray', ['🎉'])), 1),
      _createElementVNode("a", {
        href: "javascript:void(0)",
        class: "lg:block hidden text-sm underline underline-offset-4 mt-8",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
      }, "⟵ " + _toDisplayString(_ctx.$t('register.step_previous')), 1)
    ]),
    column2: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "-mt-4 lg:mt-0 mb-4 flex w-full lg:justify-center" }, [
          _createElementVNode("div", {
            id: "yivi-register",
            class: "relative max-h-fit after:hidden after:lg:block after:absolute after:-right-[1.2em] after:top-[60%] after:border-[1.25em] after:border-transparent after:border-l-white after:border-t-0 after:border-r-0 after:drop-shadow-[0_5px_16px_rgba(0,0,0,0.15)]"
          }),
          _createElementVNode("div", { class: "relative right-4 hidden lg:flex w-64" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "PubHubs mascot with attributes"
            })
          ])
        ], -1)),
        _createElementVNode("a", {
          href: "javascript:void(0)",
          class: "flex lg:hidden text-sm underline underline-offset-4",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('back')))
        }, "⟵ " + _toDisplayString(_ctx.$t('register.step_previous')), 1)
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "lg:hidden" }, [
        _createElementVNode("img", {
          class: "relative left-1/2 md:left-3/4 -mb-28 w-40 pb-4",
          src: _imports_1
        })
      ], -1)),
      _createElementVNode("form", _hoisted_5, [
        _createElementVNode("input", {
          type: "hidden",
          name: "yivi_token",
          value: yivi_token.value
        }, null, 8, _hoisted_6)
      ])
    ]),
    _: 1
  }))
}
}

})