<template>
	<h3 v-tw-class="'mb-2 font-bold light:text-gray theme-light:text-gray'">
		<slot></slot>
	</h3>
</template>

<style scoped>
	h3 {
		font-family: 'work_sansregular';
	}
</style>
