<template>
	<h2 v-tw-class="'text-lg mt-4 mb-2 font-bold light:text-gray theme-light:text-gray'">
		<slot></slot>
	</h2>
</template>

<style scoped>
	h2 {
		font-family: 'work_sansregular';
	}
</style>
