import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "group" }
const _hoisted_2 = { class: "flex relative my-2 pr-6 list-none items-start after:absolute after:right-1 after:mt-1 after:ml-2 after:border-r-2 after:border-b-2 after:p-1 after:rotate-45 group-open:after:-rotate-135 group-open:after:mt-2" }
const _hoisted_3 = { class: "bg-blue text-white w-6 h-6 shrink-0 flex justify-center items-center rounded-full mr-2" }
const _hoisted_4 = { class: "mb-2 whitespace-pre-line" }


export default /*@__PURE__*/_defineComponent({
  __name: 'Foldout',
  props: {
		question: { type: String },
	},
  setup(__props) {

	const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("details", _hoisted_1, [
    _createElementVNode("summary", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Icon, {
          type: "information",
          size: "xs"
        })
      ]),
      _createTextVNode(" " + _toDisplayString(props.question), 1)
    ]),
    _createElementVNode("p", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _cache[0] || (_cache[0] = _createTextVNode(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae tincidunt nunc, rutrum dapibus ipsum. Nunc feugiat auctor nunc, in tempor dui porttitor ac. In hac habitasse platea dictumst. Sed pellentesque ipsum est, vitae dictum lectus bibendum commodo. Sed eget erat quam. Fusce pellentesque tristique pharetra. Nam mattis scelerisque tortor, eu malesuada dolor elementum a. Vivamus fringilla augue purus, in efficitur eros fringilla ultrices. Ut pretium dui id mi cursus, sit amet vestibulum tellus maximus. Phasellus sodales pharetra arcu, nec mollis felis dictum ut. Duis sagittis pulvinar tempor. Morbi ut pretium enim, dictum porttitor lectus. Pellentesque vel augue id lorem pretium ullamcorper. Suspendisse ultricies, ante id rutrum ornare, augue sapien tristique ex, at tincidunt magna magna quis justo. Aliquam gravida lacinia urna eget egestas. "))
      ])
    ])
  ]))
}
}

})