import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-2" }
const _hoisted_2 = { class: "flex flex-col md:flex-row justify-between" }
const _hoisted_3 = { class: "flex flex-col md:flex-row justify-between" }
const _hoisted_4 = { class: "flex flex-col md:flex-row justify-between" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col md:flex-row justify-between"
}

import { onMounted } from 'vue';
	import { useSettings, Theme } from '@/store/store';
	import { useFormState, FormDataType } from '@/composables/useFormState';
	import { useDialog, buttonsSubmitCancel, DialogOk } from '@/store/store';

	import { useI18n } from 'vue-i18n';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsDialog',
  setup(__props) {

	const { t } = useI18n();

	const { data, setSubmitButton, setData, updateData, dataIsChanged, changed } = useFormState();
	const settings = useSettings();
	const dialog = useDialog();

	function noPerm() {
		return Notification.permission === 'denied' || Notification.permission === 'default';
	}

	function askPerm() {
		Notification.requestPermission().then((result) => {
			console.debug(result);
		});
	}

	setData({
		theme: { value: settings.getSetTheme as FormDataType },
		language: { value: settings.getActiveLanguage as FormDataType },
		timeformat: { value: settings.getTimeFormat as FormDataType },
	});

	onMounted(() => {
		dialog.addCallback(DialogOk, () => {
			if (changed) {
				let sendSettings = false;
				if (dataIsChanged('theme')) {
					settings.setTheme(data.theme.value as Theme);
					sendSettings = true;
				}
				if (dataIsChanged('language')) {
					settings.setLanguage(data.language.value as string);
					sendSettings = true;
				}
				if (dataIsChanged('timeformat')) {
					settings.setTimeFormat(data.timeformat.value as string);
					sendSettings = true;
				}
				if (sendSettings) {
					settings.sendSettings();
				}
			}
		});
		setSubmitButton(dialog.properties.buttons[0]);
	});

return (_ctx: any,_cache: any) => {
  const _component_Label = _resolveComponent("Label")!
  const _component_ButtonGroup = _resolveComponent("ButtonGroup")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    title: _ctx.$t('settings.title'),
    buttons: _unref(buttonsSubmitCancel),
    width: "w-3/5"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('settings.theme')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ButtonGroup, {
            size: "sm",
            modelValue: _unref(data).theme.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(data).theme.value) = $event)),
            value: _unref(data).theme.value,
            options: _unref(settings).getThemeOptions(_unref(t)),
            onChanged: _cache[1] || (_cache[1] = ($event: any) => (_unref(updateData)('theme', $event)))
          }, null, 8, ["modelValue", "value", "options"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('settings.language')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ButtonGroup, {
            size: "sm",
            modelValue: _unref(data).language.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(data).language.value) = $event)),
            value: _unref(data).language.value,
            options: _unref(settings).getLanguageOptions,
            onChanged: _cache[3] || (_cache[3] = ($event: any) => (_unref(updateData)('language', $event)))
          }, null, 8, ["modelValue", "value", "options"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('settings.timeformat')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ButtonGroup, {
            size: "sm",
            modelValue: _unref(data).timeformat.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(data).timeformat.value) = $event)),
            value: _unref(data).timeformat.value,
            options: _unref(settings).getTimeFormatOptions(_unref(t)),
            onChanged: _cache[5] || (_cache[5] = ($event: any) => (_unref(updateData)('timeformat', $event)))
          }, null, 8, ["modelValue", "value", "options"])
        ]),
        (noPerm())
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("label", null, _toDisplayString(_unref(t)('settings.notifications')), 1),
              _createVNode(_component_Button, {
                onClick: _cache[6] || (_cache[6] = ($event: any) => (askPerm())),
                size: "sm"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('settings.notifications_allow')), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title", "buttons"]))
}
}

})