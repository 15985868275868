import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex gap-4 mb-6" }


export default /*@__PURE__*/_defineComponent({
  __name: 'Progressbar',
  props: {
		currentStep: {
			type: Number,
			required: true,
		},
		totalSteps: {
			type: Number,
			default: 3,
		},
	},
  setup(__props) {

	const props = __props;

	function dynamicClass(i: number): string {
		let c = '';
		if (i <= props.currentStep) {
			c += 'bg-blue';
		} else {
			c += 'bg-gray-light';
		}
		return c;
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.totalSteps, (i) => {
      return (_openBlock(), _createElementBlock("li", {
        class: _normalizeClass(["text-white w-6 h-6 shrink-0 flex font-bold justify-center rounded-full", dynamicClass(i)]),
        key: i
      }, _toDisplayString(i < props.currentStep ? '✓' : `${i}`), 3))
    }), 128))
  ]))
}
}

})