import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../../assets/yivi-logo.svg'
import _imports_1 from '../../../assets/mascot-yivi-app-small.svg'


const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = {
  for: "yiviDownloaded",
  class: "flex mt-4 font-bold"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "list-disc list-outside pl-4 my-4" }
const _hoisted_6 = { class: "hidden lg:flex items-start lg:justify-center" }
const _hoisted_7 = { class: "relative bg-white shadow-md rounded-2xl p-4 after:absolute after:-bottom-[1.2rem] after:right-[10%] after:border-[1.25em] after:border-solid after:border-transparent after:border-t-white after:border-b-0 after:border-r-0 after:drop-shadow-md" }
const _hoisted_8 = { class: "flex my-4 justify-center" }

import { ref } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'OnboardingStep3',
  setup(__props) {

	const checked = ref(false);

return (_ctx: any,_cache: any) => {
  const _component_Progressbar = _resolveComponent("Progressbar")!
  const _component_OnboardingTitle = _resolveComponent("OnboardingTitle")!
  const _component_H3 = _resolveComponent("H3")!
  const _component_DownloadLinks = _resolveComponent("DownloadLinks")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Foldout = _resolveComponent("Foldout")!
  const _component_H1 = _resolveComponent("H1")!
  const _component_OnboardingTemplate = _resolveComponent("OnboardingTemplate")!

  return (_openBlock(), _createBlock(_component_OnboardingTemplate, null, {
    column1: _withCtx(() => [
      _createVNode(_component_Progressbar, { currentStep: 1 }),
      _createVNode(_component_OnboardingTitle),
      _createElementVNode("p", null, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('register.step', [1])), 1),
        _createTextVNode("   " + _toDisplayString(_ctx.$t('register.install_yivi')), 1)
      ]),
      _createElementVNode("p", _hoisted_1, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('register.yivi_what')), 1),
        _createTextVNode("   " + _toDisplayString(_ctx.$t('register.yivi_short_description')), 1)
      ]),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('register.yivi_download')), 1),
      _createVNode(_component_H3, { class: "lg:hidden mt-4 theme-light:text-black mb-0" }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Download Yivi")
        ])),
        _: 1
      }),
      _createVNode(_component_DownloadLinks, { class: "lg:hidden mt-2" }),
      _createElementVNode("label", _hoisted_3, [
        _createVNode(_component_Checkbox, {
          id: "yiviDownloaded",
          class: "h-[16px] w-[16px] mt-1 sm:mt-0.5 mr-2",
          modelValue: checked.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checked).value = $event)),
          color: 'blue'
        }, null, 8, ["modelValue"]),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('register.yivi_installed')), 1)
      ]),
      _createVNode(_component_Button, {
        class: "w-fit mt-6 mb-4",
        disabled: !checked.value,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('next')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('register.step_go_to', [2])) + " ⟶", 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_H3, { class: "mt-4 theme-light:text-black mb-0" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('register.more')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Foldout, {
        question: `${_ctx.$t('register.yivi_workings')}`
      }, {
        default: _withCtx(() => [
          _createVNode(_component_i18n_t, { keypath: "register.yivi_workings_answer" }, {
            hyperlink: _withCtx(() => [
              _createElementVNode("a", {
                class: "font-semibold text-blue underline hover:no-underline",
                href: `${_ctx.$t('register.yivi_link')}`,
                target: "_blank"
              }, "Yivi.app", 8, _hoisted_4)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["question"]),
      _createVNode(_component_Foldout, {
        question: `${_ctx.$t('register.yivi_why')}`
      }, {
        default: _withCtx(() => [
          _createVNode(_component_i18n_t, { keypath: "register.yivi_why_answer" }, {
            reasons: _withCtx(() => [
              _createElementVNode("ol", _hoisted_5, [
                _createElementVNode("li", null, _toDisplayString(_ctx.$t('register.yivi_why_central_login')), 1),
                _createElementVNode("li", null, _toDisplayString(_ctx.$t('register.yivi_why_room_login')), 1)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["question"]),
      _createElementVNode("a", {
        href: "javascript:void(0)",
        class: "text-sm underline underline-offset-4 mt-6",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('back')))
      }, "⟵ " + _toDisplayString(_ctx.$t('register.step_previous')), 1)
    ]),
    column2: _withCtx(() => [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_H1, { class: "text-xl xl:text-2xl !text-black mr-2" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Download")
              ])),
              _: 1
            }),
            _cache[5] || (_cache[5] = _createElementVNode("img", {
              class: "h-[1.75rem]",
              src: _imports_0,
              alt: "Yivi"
            }, null, -1))
          ]),
          _createVNode(_component_DownloadLinks, { class: "justify-center" })
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "relative right-16 -mr-16 top-56 mb-56 flex min-w-60" }, [
          _createElementVNode("img", {
            src: _imports_1,
            alt: "PubHubs mascot with Yivi app"
          })
        ], -1))
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "lg:hidden -mt-8" }, [
        _createElementVNode("img", {
          class: "relative left-1/2 md:left-3/4 -mb-20 w-40 pb-4",
          src: _imports_1
        })
      ], -1))
    ]),
    _: 1
  }))
}
}

})