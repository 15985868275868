import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../../assets/mascot-attributes.svg'
import _imports_1 from '../../../assets/mascot-yivi-app-small.svg'


const _hoisted_1 = { class: "mt-4 mb-2" }
const _hoisted_2 = { class: "my-2" }
const _hoisted_3 = { class: "my-2" }
const _hoisted_4 = {
  for: "attributesAdded",
  class: "flex mt-6 font-bold"
}

import { ref } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'OnboardingStep4',
  setup(__props) {

	const checked = ref(false);

return (_ctx: any,_cache: any) => {
  const _component_Progressbar = _resolveComponent("Progressbar")!
  const _component_OnboardingTitle = _resolveComponent("OnboardingTitle")!
  const _component_Foldout = _resolveComponent("Foldout")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_OnboardingTemplate = _resolveComponent("OnboardingTemplate")!

  return (_openBlock(), _createBlock(_component_OnboardingTemplate, null, {
    column1: _withCtx(() => [
      _createVNode(_component_Progressbar, { currentStep: 2 }),
      _createVNode(_component_OnboardingTitle),
      _createElementVNode("p", null, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('register.step', [2])), 1),
        _createTextVNode("   " + _toDisplayString(_ctx.$t('register.overview_step2')) + ". ", 1)
      ]),
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('register.rewards.good_job')), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('register.data_request')), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('register.data_add')), 1),
      _createVNode(_component_Foldout, {
        question: `${_ctx.$t('register.data_add_how')}`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('register.data_add_how_answer')), 1)
        ]),
        _: 1
      }, 8, ["question"]),
      _createVNode(_component_Foldout, {
        question: `${_ctx.$t('register.data_why')}`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('register.data_why_answer')), 1)
        ]),
        _: 1
      }, 8, ["question"]),
      _createElementVNode("label", _hoisted_4, [
        _createVNode(_component_Checkbox, {
          id: "attributesAdded",
          class: "h-[16px] w-[16px] mt-1 sm:mt-0.5 mr-2",
          modelValue: checked.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checked).value = $event)),
          color: 'blue'
        }, null, 8, ["modelValue"]),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('register.data_added')), 1)
      ]),
      _createVNode(_component_Button, {
        class: "w-fit my-4",
        disabled: !checked.value,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('next')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('register.step_go_to', [3])) + " ⟶", 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createElementVNode("a", {
        href: "javascript:void(0)",
        class: "text-sm underline underline-offset-4 mt-4",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('back')))
      }, "⟵ " + _toDisplayString(_ctx.$t('register.step_previous')), 1)
    ]),
    column2: _withCtx(() => _cache[3] || (_cache[3] = [
      _createElementVNode("div", { class: "hidden lg:flex justify-center" }, [
        _createElementVNode("div", { class: "w-3/4" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "PubHubs mascot with attributes"
          })
        ])
      ], -1),
      _createElementVNode("div", { class: "lg:hidden -mt-8" }, [
        _createElementVNode("img", {
          class: "relative left-1/2 md:left-3/4 -mb-20 w-40 pb-4",
          src: _imports_1
        })
      ], -1)
    ])),
    _: 1
  }))
}
}

})